import * as React from "react";
import { SubBannerCardType } from "../../../assets/Types/AcfGroupTypes/SubBannerCard.type";
import styles from "./SubBannerCard.module.scss";

const SubBannerCard: React.FC<{ subBannerElem: SubBannerCardType }> = ({
  subBannerElem: { leftContent, rightContent, className },
}) => (
  <div
    data-testid="div:SubBannerCard"
    className={`${styles.container} ${className}`}
  >
    <div className={styles.flexContainer}>
      {leftContent}
      <p>{rightContent}</p>
    </div>
  </div>
);

export default SubBannerCard;
