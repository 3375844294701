import * as React from "react";
import { ReviewsItemType } from "../../assets/Types/AcfGroupTypes/ReviewsPg.type";
import ReviewStars from "../Reviews/ReviewStars";
import { Size } from "../../assets/Enums/Size.enum";

import styles from "./ReviewsCard.module.scss";

export interface ReviewsCardProps {
  reviewsGridListItem: ReviewsItemType;
  className?: string;
}

const ReviewsCard: React.FC<ReviewsCardProps> = ({
  reviewsGridListItem: { quote, quotee, source, sourceUrl },
  className,
}) => {
  return (
    <div
      data-testid="div:ReviewsCard"
      className={`${styles.reviewsCardContainer} ${className}`}
    >
      <span className="quotation">&quot;</span>
      <div
        dangerouslySetInnerHTML={{ __html: quote }}
        className={styles.reviewsQuote}
      />
      <span className="quotation">&quot;</span>
      <span className={styles.author}>— {quotee}</span>
      <ReviewStars size={Size.SMALL} />
      <a
        href={sourceUrl}
        className={styles.reviewsSource}
        target="_blank"
        rel="noopener noreferrer"
      >
        {source}
      </a>
    </div>
  );
};

export default ReviewsCard;
