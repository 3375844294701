import React from "react";
import Masonry from "react-masonry-css";
import { ReviewsListType } from "../../assets/Types/AcfGroupTypes/ReviewsPg.type";
import ReviewsCard from "../../components/ReviewsCard/ReviewsCard";

import styles from "./ReviewsCardsContainer.module.scss";

type ReviewsCardsContainerProps = {
  reviewsGridList: ReviewsListType;
  start?: number;
  end?: number;
  compliance?: string;
};

const ReviewsCardsContainer: React.FC<ReviewsCardsContainerProps> = ({
  reviewsGridList,
  start,
  end,
  compliance,
}) => {
  const reviewsList = reviewsGridList.slice(start, end).map((review, index) => {
    return <ReviewsCard reviewsGridListItem={review} key={index} />;
  });
  const breakpointColumns = {
    default: 3,
    1024: 2,
    767: 1,
  };

  let capterraCompliance;
  if (compliance) {
    capterraCompliance = (
      <div className={`full-width shorter ${styles.reviewsCapterra}`}>
        {compliance}
      </div>
    );
  }

  return (
    <section
      data-testid="section:reviewsContainer"
      className={`pad-top--large ${styles.reviewsContainer}`}
    >
      <div className="full-width pad-bottom--large">
        <Masonry
          breakpointCols={breakpointColumns}
          className={styles.masonryGrid}
          columnClassName={styles.masonryGrid_column}
        >
          {reviewsList}
        </Masonry>
      </div>
      {capterraCompliance}
    </section>
  );
};

export default ReviewsCardsContainer;
