import * as React from "react";
import SubBannerCard from "../SubBannerCard/SubBannerCard";
import { HeroSinglePProps } from "../../../assets/Types/AcfGroupTypes/HeroSingleP.type";
import DownArrowSmall from "../../SVGIcons/DownArrowSmall/DownArrowSmall";

import styles from "./ReviewsBannerPrimary.module.scss";

const ReviewsBannerPrimary: React.FC<{
  props: HeroSinglePProps;
  withCard?: boolean;
  classNames?: string | null;
}> = ({ props: { heroSingleSubcopy, heroSingleTitle }, withCard = true }) => {
  return (
    <section
      data-testid="section:ReviewsBannerPrimary"
      className={`pad-top--large-sans-header pad-bottom--large ${styles.reviewBannerContainer}`}
    >
      <div className="full-width shorter">
        <div className="text-alignment">
          <h1 data-testid="H1:title" className={styles.heroSingleTitle}>
            {heroSingleTitle}
          </h1>
        </div>
        <p className={styles.heroSingleSubcopy}>{heroSingleSubcopy}</p>
      </div>
      {withCard && (
        <SubBannerCard
          subBannerElem={{
            leftContent: <DownArrowSmall />,
            rightContent: <>See their words</>,
          }}
        />
      )}
    </section>
  );
};

export default ReviewsBannerPrimary;
