import * as React from "react";
import { graphql, PageProps } from "gatsby";
import MainLayout from "../../layouts/main";
import ReviewsCardsContainer from "../../containers/ReviewsCardsContainer/ReviewsCardsContainer";
import TestimonialSliderPContainer from "../../containers/TestimonialSliderPContainer/TestimonialSliderPContainer";
import { TestimonialSliderPType } from "../../assets/Types/AcfGroupTypes/TestimonialSliderP.type";
import { ReviewsListType } from "../../assets/Types/AcfGroupTypes/ReviewsPg.type";
import ReviewsBannerPrimary from "../../components/Banners/ReviewsBannerPrimary/ReviewsBannerPrimary";
import { HeroSinglePProps } from "../../assets/Types/AcfGroupTypes/HeroSingleP.type";
import { YoastSEOType } from "../../assets/Types";
import SEO from "gatsby-plugin-wpgraphql-seo";
import { ReviewsCapterraType } from "@types";
import { deIndexSeo } from "../../utils/deIndexSeo";

type ReviewsProps = {
  wpPage: {
    seo: YoastSEOType;
    template: {
      heroSingleP: HeroSinglePProps;
      reviewsCapterra: ReviewsCapterraType;
      reviewsGridP: {
        reviewsGridList: ReviewsListType;
      };
      testimonialSliderP: TestimonialSliderPType;
    };
    title: string;
  };
};

const ReviewsPage: React.FC<PageProps<ReviewsProps>> = ({ data }) => {
  const { wpPage } = data;
  const {
    template: {
      heroSingleP,
      reviewsCapterra: { reviewsCapterraText },
      reviewsGridP: { reviewsGridList },
      testimonialSliderP,
    },
  } = wpPage;
  testimonialSliderP.bWhite = true;
  return (
    <MainLayout hasFooterLargeCta={true}>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <ReviewsBannerPrimary props={heroSingleP} />
      <ReviewsCardsContainer
        reviewsGridList={reviewsGridList}
        start={0}
        end={12}
      />
      <TestimonialSliderPContainer props={testimonialSliderP} />
      <ReviewsCardsContainer
        reviewsGridList={reviewsGridList}
        start={12}
        end={undefined}
        compliance={reviewsCapterraText}
      />
    </MainLayout>
  );
};

export default ReviewsPage;

export const query = graphql`
  query ReviewsTemplateQuery($id: String) {
    wpPage(id: { eq: $id }) {
      id
      ...YoastSEOFragment
      template {
        templateName
        ... on WpReviewsTemplate {
          templateName
          heroSingleP {
            heroSingleTitle
            heroSingleSubcopy
            heroSingleLinkText
            heroSingleLink
            heroSingleBadge
          }
          reviewsGridP {
            reviewsGridList {
              quote
              quotee
              location
              source
              sourceUrl
              stars
            }
          }
          reviewsCapterra {
            reviewsCapterraText
          }
          testimonialSliderP {
            testimonialHeadline
            testimonialSubcopy
            testimonialModuleRelationship {
              ... on WpTestimonial {
                id
                databaseId
                title
                slug
                uri
                testimonialsC {
                  testimonialVideoBackgroundImage {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 800
                          placeholder: BLURRED
                          formats: [WEBP, JPG, AUTO]
                        )
                      }
                    }
                  }
                  testimonialWistiaId
                  testimonialsAuthor
                  testimonialsLabel
                  testimonialsLocation
                  testimonialsQuote
                }
              }
            }
          }
        }
      }
    }
  }
`;
